import { ApiLogon } from "@tucktrucks/platform-base-private";
import store from "@/store";

export function Login(username, password) {
  return store.state.apiPrivate.client.endpoints.tokens
    .getBearerToken(
      new ApiLogon({
        username: username,
        password: password,
      })
    )
    .then((loginResponse) => {
      if (loginResponse.status == 200) {
        // Login was successful.
        return loginResponse.data.data;
      }

      return Promise.reject(loginResponse);
    })
    .then(async (tokens) => {
      tokens.username = username;
      await store.dispatch("authentication/storeTokens", tokens);

      return {
        authenticated: true,
      };
    })
    .catch(async (ex) => {
      await store.dispatch("authentication/logout");

      const reason = "Failed to login using provided details.";
      window.log.warn(reason, ex);

      return {
        authenticated: false,
        reason: reason,
      };
    });
}

export function GetOutlets() {
  return store.state.apiPrivate.client.endpoints.user
    .getOperations()
    .then((operationsResponse) => {
      if (operationsResponse.status == 200) {
        return operationsResponse.data.data;
      }

      return Promise.reject(operationsResponse);
    })
    .then((data) => {
      const outlets = data.outlets;

      try {
        if (outlets != null && outlets.length > 0) {
          return outlets;
        }
      } catch {
        // Ignored
      }

      return Promise.reject(data);
    })
    .then(async (outlets) => {
      await store.dispatch("authentication/storeOutlets", outlets);

      return {
        authenticated: true,
        hasOutlets: true,
        outlets: outlets,
      };
    })
    .catch(async (ex) => {
      await store.dispatch("authentication/logout");

      const reason =
        "This user account cannot manage any current kitchen schedules.";
      window.log.warn(reason, ex);

      return {
        authenticated: false,
        hasOutlets: false,
        reason: reason,
      };
    });
}

export function Authenticate(to) {
  const route = to.name;

  switch (route) {
    case "/login": {
      return store
        .dispatch("authentication/recover")
        .then((response) => {
          if (response.authenticated) {
            return {
              action: "redirect",
              target: "/services",
            };
          }

          return {
            action: "none",
          };
        })
        .catch(() => {
          return {
            action: "none",
          };
        });
    }

    case "/services": {
      return store
        .dispatch("authentication/recover")
        .then(async (tokens) => {
          if (tokens.authenticated) {
            const outletResponse = await GetOutlets();

            if (outletResponse.authenticated && outletResponse.hasOutlets) {
              return {
                action: "none",
              };
            }

            return {
              action: "logout",
              reason: "Failed to find applicable outlets for this user.",
            };
          }

          return {
            action: "logout",
            reason: "User is not authenticated.",
          };
        })
        .catch(() => {
          return {
            action: "logout",
          };
        });
    }

    case "/service/id": {
      const serviceId = to.params.serviceId;

      return store
        .dispatch("authentication/recover")
        .then(async (tokens) => {
          if (tokens.authenticated) {
            const outletResponse = await GetOutlets();

            if (outletResponse.authenticated && outletResponse.hasOutlets) {
              const serviceResponse =
                await store.state.apiPrivate.client.endpoints.services.getbyId(
                  serviceId
                );

              switch (serviceResponse.status) {
                case 200: {
                  // If the outlets list contains the outlet for this service, check in here.
                  let outlet = outletResponse.outlets.find(
                    (outlets) =>
                      outlets.id == serviceResponse.data.data.outletId
                  );

                  if (outlet != null) {
                    await store.dispatch("authentication/storeService", {
                      service: serviceResponse.data.data,
                      outlet: outlet,
                    });

                    return {
                      action: "none",
                    };
                  }

                  break;
                }
                case 404: {
                  return {
                    action: "logout",
                    reason: "This service could not be found.",
                  };
                }
              }

              return {
                action: "logout",
                reason: "This user does not have access to this service.",
                serviceId: serviceId,
              };
            }

            return {
              action: "logout",
              reason: "Failed to find applicable outlets for this user.",
              serviceId: serviceId,
            };
          }

          return {
            action: "logout",
            reason: "User is not authenticated.",
            serviceId: serviceId,
          };
        })
        .catch(() => {
          return {
            action: "logout",
          };
        });
    }
  }
}
