<template>
  <router-view></router-view>
</template>

<script>
  export default {
    errorCaptured(err, vm, info) {
      window.log.error(
        `An unhandled exception was caught on the main App component.\n${err?.message}\n`,
        {
          err,
          vm,
          info,
        }
      );
      return false;
    },
  };
</script>

<style>
  body {
    margin: 0;
    font-family: "Stolzl", sans-serif;
  }

  select,
  button {
    font-family: "Stolzl", sans-serif;
  }
</style>
