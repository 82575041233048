export async function takeZettlePayment(
  intentId,
  action,
  invokeProtocolAutomatically = true
) {
  const url = `tt-payments://payment/${intentId}`;

  window.log?.info("[💷] Opening Zettle payment integration app using: " + url);

  if (action != null) {
    action(url);
  }

  // Create a link with the above URL and auto click it.
  if (invokeProtocolAutomatically) {
    const a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);

    a.click();

    // Wait 1 second and remove it.
    window.setTimeout(() => {
      document.body.removeChild(a);
    }, 1000);
  }
}
