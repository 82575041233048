export default {
  namespaced: true,

  state() {
    return {
      selectedTickets: [],
      selectedGroups: [],
      selectedTicketsChannels: [],
      selectedStatus: null,
    };
  },

  mutations: {
    ADD_ORDER(state, order) {
      state.selectedTickets?.push(order?.id);
      state.selectedTicketsChannels.push(order.channel);
      state.selectedStatus = order.status;
    },
    REMOVE_ORDER(state, orderId) {
      state.selectedTickets.splice(state.selectedTickets.indexOf(orderId), 1);
    },
    ADD_GROUP(state, groupkey) {
      state.selectedGroups.push(groupkey);
    },
    REMOVE_GROUP(state, groupkey) {
      state.selectedGroups.splice(state.selectedGroups.indexOf(groupkey), 1);
      if (state.selectedTickets.length === 0) state.selectedStatus = null;
    },
    ADD_CHANNEL(state, channel) {
      state.selectedTicketsChannels.push(channel);
    },
    REMOVE_CHANNEL(state, channel) {
      state.selectedTicketsChannels.splice(
        state.selectedTicketsChannels.indexOf(channel),
        1
      );
    },
    UPDATE_STATUS(state, status) {
      state.selectedStatus = status;
    },
    RESET_SELECT(state) {
      state.selectedTickets = [];
      state.selectedTicketsChannels = [];
      state.selectedGroups = [];
      state.selectedStatus = null;
    },
  },
  actions: {
    handleTicketSelection({ state, commit, dispatch }, payload) {
      const { entry, group, groupkey } = payload;

      if (!state.selectedTickets.includes(entry.order.id)) {
        commit("ADD_ORDER", entry.order);
      } else {
        commit("REMOVE_ORDER", entry.order.id);
        commit("REMOVE_CHANNEL", entry.order.channel);
      }
      dispatch("checkGroup", { group, groupkey });
    },

    handleGroupSelection({ state, commit, dispatch }, payload) {
      const { group, groupkey } = payload;

      if (!state.selectedGroups.includes(groupkey)) {
        // Group checbox checked, add all tickets to selection
        for (const entry of group) {
          const { id } = entry.order;
          if (state.selectedTickets.indexOf(id) < 0) {
            commit("ADD_ORDER", entry.order);
          }
        }
        dispatch("selectGroup", groupkey);
      } else {
        // Group checbox Unchecked, remove all tickets to selection
        for (const entry of group) {
          const { id, channel } = entry.order;
          if (state.selectedTickets.indexOf(id) >= 0) {
            commit("REMOVE_ORDER", id);
            commit("REMOVE_CHANNEL", channel);
          }
        }
        dispatch("deselectGroup", groupkey);
      }
    },

    checkGroup({ state, dispatch }, payload) {
      const { group, groupkey } = payload;
      // if every ticket in this group is selected, set the group to be selected
      if (
        group.every((entry) => state.selectedTickets.includes(entry.order.id))
      ) {
        dispatch("selectGroup", groupkey);
      } else {
        dispatch("deselectGroup", groupkey);
      }
    },

    selectGroup({ state, commit }, groupkey) {
      if (!state.selectedGroups.includes(groupkey)) {
        commit("ADD_GROUP", groupkey);
      }
    },

    deselectGroup({ state, commit }, groupkey) {
      if (!state.selectedStatus) return;

      if (state.selectedGroups.includes(groupkey)) {
        commit("REMOVE_GROUP", groupkey);
      }
    },

    reset({ commit }) {
      commit("RESET_SELECT");
    },
  },
  getters: {
    selectedTickets: (state) => state.selectedTickets,
    selectedGroups: (state) => state.selectedGroups,
    selectedStatus: (state) => state.selectedStatus,
  },
};
