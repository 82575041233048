import store from "@/store";
import { createZettleOrderIntent } from "@/helpers/integration-zettle-order.js";
import { createZettleCartIntent } from "@/helpers/integration-zettle-cart.js";
import { takeZettlePayment } from "@/helpers/integration-zettle.js";
import { useToast } from "vue-toastification";

export default {
  namespaced: true,

  state: {
    paymentPending: 0,
    locked: false,
    zettleCurrentIntent: null,
    zettleCurrentIntentURL: null,
    zettleMessage: "Loading...",
    zettlePollEntered: false,
    job: null,
    phoneOrderingPaid: false,
  },

  mutations: {
    LOCK(state) {
      state.locked = true;
    },

    UNLOCK(state) {
      state.locked = false;
    },

    UPDATE_PAYMENT_PENDING(state, payload) {
      state.paymentPending = payload;
    },

    UPDATE_INTENT(state, payload) {
      let url;
      if (payload.intent?.id)
        url = `tt-payments://payment/${payload.intent.id}`;

      state.zettleCurrentIntent = payload.intent;
      state.zettleCurrentIntentURL = url;
    },

    UPDATE_ZETTLE_MESSAGE(state, payload) {
      state.zettleMessage = payload;
    },

    START_JOB(state, payload) {
      state.job = window.setInterval(() => {
        store.dispatch("payment/getZettleIntentStatus", {
          ifPhoneOrdering: payload?.ifPhoneOrdering || false,
        });
      }, 5000);
    },

    STOP_JOB(state) {
      state.job = window.clearInterval(state.job);
    },

    UPDATE_ENTERED_STATUS(state, payload) {
      state.zettlePollEntered = payload;
    },

    UPDATE_PHONE_ORDERING_PAID(state, payload) {
      state.phoneOrderingPaid = payload;
    },
  },
  actions: {
    /* Cash */
    async createCartPaymentCash({ commit }, payload) {
      commit("LOCK");
      const { amount, id, key } = payload;

      return store.state.apiPrivate.client.endpoints.cartPaymentsCash
        .create(amount, id, key)
        .then(({ status, data }) => {
          if (status === 200) {
            const paymentPending = data.meta["payment-pending"];
            store.dispatch("cart/updatePendingAmount", paymentPending);

            if (!paymentPending) {
              useToast().info("Cart is now funded.");

              store.dispatch("cart/confirm");
              commit("UPDATE_PAYMENT_PENDING", 0);
            }
          }
          return status;
        })
        .finally(() => commit("UNLOCK"));
    },

    async createOrderPaymentCash({ commit }, payload) {
      commit("LOCK");
      commit("UPDATE_PHONE_ORDERING_PAID", false);
      const { amount, id, key } = payload;

      return store.state.apiPrivate.client.endpoints.orderPaymentsCash
        .create(amount, id, key)
        .then(({ status, data }) => {
          if (status === 200) {
            const paymentPending = data.meta["payment-pending"];
            store.dispatch("cart/updatePendingAmount", paymentPending);

            if (!paymentPending) {
              useToast().info("Cart is now funded.");
              commit("UPDATE_PHONE_ORDERING_PAID", true);
              commit("UPDATE_PAYMENT_PENDING", 0);
            }
          }
          return status;
        })
        .finally(() => commit("UNLOCK"));
    },

    /* Card Manual */
    async createCartPaymentCardManual({ commit }, payload) {
      commit("LOCK");
      const { amount, id, key } = payload;

      return store.state.apiPrivate.client.endpoints.cartPaymentsExternal
        .create(amount, id, key)
        .then(({ status, data }) => {
          if (status === 200) {
            const paymentPending = data.meta["payment-pending"];
            store.dispatch("cart/updatePendingAmount", paymentPending);

            if (!paymentPending) {
              useToast().info("Cart is now funded.");

              store.dispatch("cart/confirm");
              commit("UPDATE_PAYMENT_PENDING", 0);
            }
          }
          return status;
        })
        .finally(() => commit("UNLOCK"));
    },

    async createOrderPaymentCardManual({ commit }, payload) {
      commit("LOCK");
      commit("UPDATE_PHONE_ORDERING_PAID", false);
      const { amount, id, key } = payload;

      return store.state.apiPrivate.client.endpoints.orderPaymentsExternal
        .create(amount, id, key)
        .then(({ status, data }) => {
          if (status === 200) {
            const paymentPending = data.meta["payment-pending"];
            store.dispatch("cart/updatePendingAmount", paymentPending);

            if (!paymentPending) {
              useToast().info("Cart is now funded.");
              commit("UPDATE_PHONE_ORDERING_PAID", true);
              commit("UPDATE_PAYMENT_PENDING", 0);
            }
          }
          return status;
        })
        .finally(() => commit("UNLOCK"));
    },

    /* Zettle */
    async createCartZettlePayment({ commit }, payload) {
      commit("LOCK");
      const { amount, id, key } = payload;

      createZettleCartIntent(amount, id, key).then(
        ({ success, intent, message }) => {
          commit("UPDATE_ZETTLE_MESSAGE", message);

          if (success) {
            takeZettlePayment(intent.id);
            commit("UPDATE_INTENT", { intent });
            commit("START_JOB");
          }
        }
      );
    },

    async createOrderZettlePayment({ commit }, payload) {
      commit("LOCK");
      const { amount, id, key } = payload;

      createZettleOrderIntent(amount, id, key).then(
        ({ success, intent, message }) => {
          commit("UPDATE_ZETTLE_MESSAGE", message);

          if (success) {
            takeZettlePayment(intent.id);
            commit("UPDATE_INTENT", { intent });
            commit("START_JOB", { ifPhoneOrdering: true });
          }
        }
      );
    },

    /* Zettle Status */
    async getZettleIntentStatus(
      { dispatch, commit, state },
      { ifPhoneOrdering }
    ) {
      store.state.apiPrivate.client.endpoints.paymentIntentsZettle
        .getbyId(state.zettleCurrentIntent.id)
        .then(({ data, status }) => {
          if (status >= 200 && status <= 204) {
            return data.data;
          }
        })
        .then(async (data) => {
          commit("UPDATE_INTENT", { intent: data });
          commit("UPDATE_ZETTLE_MESSAGE", "Payment status: " + data.status);

          switch (data.status) {
            case "PaymentTaken":
              /* Stop Job & Validate, Confirm Cart */
              {
                /* Stop the second poll if the first poll is still running */

                if (state.zettlePollEntered) {
                  break;
                }

                commit("UPDATE_ENTERED_STATUS", true);
                commit("STOP_JOB");

                const { status, data } = await store.dispatch(
                  "cart/validateCart"
                );

                if (status === 200 && !data.paymentPending) {
                  useToast().info("Cart is now funded.");

                  /* Confirm & Clear the Cart */
                  /* cart/confirm just for Cart, not Order */
                  if (!ifPhoneOrdering) {
                    await store.dispatch("cart/confirm");
                  } else {
                    commit("UPDATE_PHONE_ORDERING_PAID", true);
                  }
                  commit("UPDATE_INTENT", { intent: null });
                }

                /* Unlock button & Reset poll status for next payment taken */
                commit("UNLOCK");
                commit("UPDATE_ENTERED_STATUS", false);
                commit("UPDATE_ZETTLE_MESSAGE", "Loading...");
              }
              break;

            case "Cancelled":
              /* Cancel Zettle Payment */
              dispatch("cancelZettlePayment");
              useToast().warning("Zettle payment cancelled.");
              break;
            case "Failed":
              /* Cancel Zettle Payment */
              dispatch("cancelZettlePayment");
              useToast().error("Zettle payment was unsuccessful.");
              break;
            case "Ready":
            case "Pending":
            default:
              break;
          }
        });
    },

    /* Cancel Zettle Payment */
    async cancelZettlePayment({ commit }) {
      commit("UPDATE_INTENT", { intent: null });
      commit("STOP_JOB");
      commit("UNLOCK");
      commit("UPDATE_ENTERED_STATUS", false);
      commit("UPDATE_ZETTLE_MESSAGE", "Loading...");
    },
  },
  getters: {
    getLock: (state) => state.locked,
    getIntent: (state) => state.zettleCurrentIntent,
    getIntentURL: (state) => state.zettleCurrentIntentURL,
    getPaymentPending: (state) => state.paymentPending,
    getZettleMessage: (state) => state.zettleMessage,
  },
};
