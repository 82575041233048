import { LocalDateTime, Duration } from "@js-joda/core";
import store from "@/store";

export default {
  namespaced: true,

  state: {
    job: null,
    lastUpdated: null,
    cacheDuration: Duration.ofMinutes(0),
    orderId: null,
    intentId: null,
    onConfirmation: {},
  },

  actions: {
    startTask({ commit }, payload) {
      commit("START_TASK", payload);
    },

    stopTask({ commit }) {
      commit("STOP_TASK");
    },

    cachedUpdate({ commit }) {
      const now = LocalDateTime.now();
      const cacheUntil = this.lastUpdated?.plus(this.cacheDuration);

      if (cacheUntil == null || now.isAfter(cacheUntil)) {
        commit("UPDATE", now);
      }
    },

    forceUpdate({ commit }) {
      const now = LocalDateTime.now();

      commit("UPDATE", now);
    },
  },

  mutations: {
    START_TASK(state, payload) {
      state.orderId = payload.orderId;
      state.intentId = payload.intentId;
      state.onConfirmation = payload.onConfirmation;

      store.dispatch("jobZettleIntent/cachedUpdate");

      state.job = window.setInterval(() => {
        store.dispatch("jobZettleIntent/forceUpdate");
      }, 5000);
    },

    UPDATE(state, now) {
      if (state.downloading) {
        return;
      }

      const apiPrivate = store.state.apiPrivate.client;

      this.downloading = true;

      apiPrivate.endpoints.paymentIntentsZettle
        .getbyId(state.orderId, state.intentId)
        .then((response) => {
          if (response.status >= 200 && response.status <= 204) {
            return response.data.data;
          }

          return Promise.reject("[💳] Failed to donwload Zettle intent.");
        })
        .then((data) => {
          state.status = data.status;

          switch (state.status) {
            case "Succeeded":
            case "Cancelled":
            case "Failed":
              state.onConfirmation(state.status);
          }

          state.lastUpdated = now;
        })
        .catch((ex) => {
          window.log.error(ex);
        })
        .finally(() => {
          state.downloading = false;
        });
    },

    STOP_TASK(state) {
      if (state.job != null) {
        window.clearInterval(state.job);
      }

      state.job = null;
    },
  },

  getters: {
    getState: (state) => () => {
      return state.state;
    },
  },
};
