import store from "@/store";
import * as apiPrivate from "@tucktrucks/platform-base-private";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    client: null,
  },

  actions: {
    instantiateClient({ commit }) {
      commit("INSTANTIATE_CLIENT");
    },

    useTokens({ state, commit }, tokens) {
      if (state.client == null) {
        commit("INSTANTIATE_CLIENT");
      }

      state.client.useTokens(tokens.bearer, tokens.refresh);
    },
  },

  mutations: {
    INSTANTIATE_CLIENT(state) {
      if (state.client == null) {
        state.client = new apiPrivate.Client();
        state.client.domain = process.env.VUE_APP_PRIVATE_API_DOMAIN;
        state.client.sessionId = window.sessionId;
        state.client.fingerprint = window.fingerprint;

        state.client.onRefresh = (tokens) => {
          store.dispatch("tokens/updateTokens", tokens);

          window.log.info("[🎟️] Refreshed token successfully.");
        };

        state.client.onRefreshFailed = () => {
          store.dispatch("tokens/clear");

          window.log.info("[🎟️] Token refresh failed.");

          router.push("/login");
        };

        state.client.onSuccess = (response) => {
          store.dispatch("network/onSuccess", response);
        };

        // Catches all errors, does not include response info.
        //state.client.onError = (error) => { window.log.error("[api]", error); }

        // Catches axios errors, includes status and response - Note, this will include intentional 404 responses etc.
        //state.client.onAxiosError = (status, error) => { window.log.error("[api.axios]", status, error) }

        // Catches axios from 400-499, includes status and response - Note, this will include intentional 404 responses etc.
        state.client.onClientError = () =>
          /*status*/
          /*error*/ {
            //window.log.warn("[api.client]", status, error)
            // if (status == 401 || status == 403) {
            //   if (router.currentRoute._value.name != "login") {
            //     router.push({ name: "login" });
            //   }
            // }
          };

        // Catches axios from 500-599, includes status and response
        state.client.onServerError = (status, error) => {
          //window.log.error("[api.server]", status, error);
          store.dispatch("network/onError", error);
        };

        // Catches network errors, i.e. server ignored request, includes response - this can be used to detect lack of connectivity
        state.client.onNetworkError = (error) => {
          //window.log.error("[api]", error)
          store.dispatch("network/onError", error);
        };

        // store.dispatch("apiPrivateCustomers/instantiate");
        // store.dispatch("apiPrivateDeliveries/instantiate");
      }
    },
  },
};
