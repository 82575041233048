import { LocalDateTime, Duration } from "@js-joda/core";
import store from "@/store";

export default {
  namespaced: true,

  state: {
    job: null,
    serviceId: null,
    lastUpdated: null,
    cacheDuration: Duration.ofMinutes(5),
  },
  actions: {
    startTask({ commit }, serviceId) {
      commit("START_TASK", serviceId);
    },
    stopTask({ commit }) {
      commit("STOP_TASK");
    },
    cachedUpdate({ commit }) {
      const now = LocalDateTime.now();
      const cacheUntil = this.lastUpdated?.plus(this.cacheDuration);

      if (cacheUntil == null || now.isAfter(cacheUntil)) {
        commit("UPDATE", now);
      }
    },
    forceUpdate({ commit }) {
      const now = LocalDateTime.now();

      commit("UPDATE", now);
    },
  },
  mutations: {
    START_TASK(state, serviceId) {
      state.serviceId = serviceId;

      store.dispatch("kitchenStock/cachedUpdate");

      state.job = window.setInterval(() => {
        store.dispatch("kitchenStock/forceUpdate");
      }, 5000);
    },
    async UPDATE(state, now) {
      const response =
        await store.state.apiPrivate.client.endpoints.kitchen.getStock(
          state.serviceId
        );

      state.stock = response.data.data;

      state.lastUpdated = now;
    },
    STOP_TASK(state) {
      if (state.job != null) {
        window.clearInterval(state.job);
        state.job = null;
      }
    },
  },
  getters: {
    getKitchenStock: (state) => {
      return state.stock;
    },
  },
};
