import { createStore } from "vuex";
import { ZoneId } from "@js-joda/core";

import settings from "@/store/settings";
import network from "@/store/network";
import authentication from "@/store/security/authentication";

import cart from "@/store/public/cart";
import payment from "@/store/public/payment";
import ticketSelect from "@/store/ticketSelect";
import collectionTimes from "@/store/public/jobs/collectionTimes";
import jobZettleIntent from "@/store/public/jobs/jobZettleIntent";

import apiPublic from "@/store/public/apiPublic";
import repoCharities from "@/store/public/repos/repoCharities";
import repoItems from "@/store/public/repos/repoItems";
import repoEvents from "@/store/public/repos/repoEvents";
import repoMenus from "@/store/public/repos/repoMenus";
import repoOrderConfirmations from "@/store/public/repos/repoOrderConfirmations";
import repoOrders from "@/store/public/repos/repoOrders";
import repoPromotions from "@/store/public/repos/repoPromotions";
import repoServices from "@/store/public/repos/repoServices";
import repoServiceItems from "@/store/public/repos/repoServiceItems";
import repoServiceComponents from "@/store/public/repos/repoServiceComponents";
import repoItemComponents from "@/store/public/repos/repoItemComponents";
import repoComponents from "@/store/public/repos/repoComponents";
import repoAllergens from "@/store/public/repos/repoAllergens";

import apiPrivate from "@/store/private/apiPrivate";

import kitchenOrders from "@/store/kitchen/kitchenOrders";
import kitchenService from "@/store/kitchen/kitchenService";
import kitchenStock from "@/store/kitchen/kitchenStock";

import filters from "@/store/viewControls/filters";

export default createStore({
  state: {
    locale: "en-GB",
    currency: "GBP",
    timezone: "Europe/London",
  },
  mutations: {},
  actions: {},

  getters: {
    getTimezone: (state) => ZoneId.of(state.timezone),
  },

  modules: {
    filters,
    authentication,
    settings,
    network,
    apiPublic,
    apiPrivate,
    cart,
    payment,
    ticketSelect,
    collectionTimes,
    jobZettleIntent,
    repoComponents,
    repoCharities,
    repoItems,
    repoEvents,
    repoMenus,
    repoOrderConfirmations,
    repoOrders,
    repoPromotions,
    repoServices,
    repoServiceItems,
    repoServiceComponents,
    repoItemComponents,
    kitchenOrders,
    kitchenService,
    kitchenStock,
    repoAllergens,
  },
});
