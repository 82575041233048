import PublicRepoStore from "./repoBase.js";
import store from "@/store";

const eventRepo = new PublicRepoStore("ApiEventRepo", 240);

eventRepo.mutations.SET_EVENT = (state, eventId) => () => {
  state.eventId = eventId;
};

eventRepo.actions.loadEvent =
  ({ commit, getters }, eventId) =>
  () => {
    const event = getters.repoEvents.getById(eventId);
    for (const service of event.services) {
      store.dispatch("repoServices/upsert", service.Id);

      // Event doesn't load the full menu, the service only does that
    }

    commit("SET_EVENT", eventId);

    return event;
  };

export default eventRepo;
