export default {
  namespaced: true,

  state: {
    notificationSounds: true,
  },
  actions: {
    initialize({ commit }, payload) {
      commit("INITIALIZE", payload);
    },
    toggleNotificationSounds({ commit }) {
      commit("TOGGLE_NOTIFICATION_SOUNDS");
    },
  },
  getters: {
    getNotificationSounds: (state) => state.notificationSounds,
  },
  mutations: {
    INITIALIZE(state) {
      const json = JSON.parse(window.localStorage.getItem("settings"));
      if (json != null) {
        state.notificationSounds = json.notificationSounds;
      }
    },
    TOGGLE_NOTIFICATION_SOUNDS(state) {
      state.notificationSounds = !state.notificationSounds;

      window.localStorage.setItem(
        "settings",
        JSON.stringify({
          notificationSounds: state.notificationSounds,
        })
      );
    },
  },
};
