// Vue
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Import the core-vue package, this will place with public-vue once those packages are in place
import "@tucktrucks/core-vue/dist/style.css";
// ------------------------------------------------------------

// Toast notifications
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// AG Grid
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

// Misc global components
import Icon from "./components/Icon";
import LoadingSpinner from "./components/LoadingSpinner";

// Vee-Validate
import { Field, Form, ErrorMessage } from "vee-validate";

// Mixins
import formatters from "@/plugins/formatters";

// V Calendar
import { Calendar } from "v-calendar";
import "v-calendar/dist/style.css";

const toastOptions = {
  position: "bottom-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  hideProgressBar: true,
  closeButton: "toast-button",
  icon: true,
};

window.setIntervalAndExecute = function (fn, t) {
  fn();
  return setInterval(fn, t);
};

const app = createApp(App)
  .use(store)
  .use(router)
  .use(formatters)
  .use(Toast, toastOptions)

  .component("Field", Field)
  .component("Form", Form)
  .component("ErrorMessage", ErrorMessage)
  .component("VCalendar", Calendar)

  .component("Icon", Icon)
  .component("LoadingSpinner", LoadingSpinner);

// *********************************************************************************************
// Domain Cookies
import { DomainCookie } from "@tucktrucks/core";
window.domainCookie = new DomainCookie(process.env.VUE_APP_COOKIE_DOMAIN);

// *********************************************************************************************
// Logging / DataDog
import {
  LogConfig,
  VueErrorHandler,
  getSessionId,
  getFingerprint,
} from "@tucktrucks/core";
window.sessionId = getSessionId();
window.fingerprint = getFingerprint();

let logConfig = LogConfig.configure().addSessionId(window.sessionId);

logConfig = logConfig.sendToDataDog({
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
  site: process.env.VUE_APP_DATADOG_SITE,
  service: process.env.VUE_APP_DATADOG_SERVICE,
  forwardErrorsToLogs: true,
});

if (process.env.NODE_ENV == "development") {
  logConfig = logConfig.sendToConsole();
}

const log = logConfig.build();
window.log = log;

app.config.errorHandler = (err, vm, info) =>
  VueErrorHandler(log, err, vm, info);

// *********************************************************************************************
// Themes

import { Theme } from "@tucktrucks/core";
Theme.install(); // Install the theme manager to window.theme

// *********************************************************************************************

store.dispatch("apiPublic/instantiateClient", window.sessionId);
store.dispatch("apiPrivate/instantiateClient", window.sessionId);
store.dispatch("network/startProbe", window.sessionId);

// *********************************************************************************************
// Audio Subsystem

// import { AudioManager } from "@tucktrucks/core";
// AudioManager.install();

// *********************************************************************************************

app.mount("#app");
app.config.globalProperties.window = window;

// register browser details
try {
  window.log.info(
    `Registering browser session for ${process.env.VUE_APP_HOST}..`,
    {
      app,
      window,
      screen,
      location,
      history,
      navigator,
      process,
      sessionId: window.sessionId,
    }
  );
} catch {
  // ignored
}
