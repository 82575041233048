import { LocalDateTime, Duration } from "@js-joda/core";
import store from "@/store";

export default {
  namespaced: true,

  state: {
    job: null,
    serviceId: null,
    lastUpdated: null,
    cacheDuration: Duration.ofMinutes(1),

    orders: [],
  },
  actions: {
    startTask({ commit }, serviceId) {
      commit("START_TASK", serviceId);
    },
    stopTask({ commit }) {
      commit("STOP_TASK");
    },
    cachedUpdate({ commit }) {
      const now = LocalDateTime.now();
      const cacheUntil = this.lastUpdated?.plus(this.cacheDuration);

      if (cacheUntil == null || now.isAfter(cacheUntil)) {
        commit("UPDATE", now);
      }
    },
    forceUpdate({ commit }) {
      const now = LocalDateTime.now();

      commit("UPDATE", now);
    },
  },
  mutations: {
    START_TASK(state, serviceId) {
      state.serviceId = serviceId;

      store.dispatch("kitchenOrders/cachedUpdate");

      state.job = window.setInterval(() => {
        store.dispatch("kitchenOrders/forceUpdate");
      }, 20000);
    },

    UPDATE(state, now) {
      store.state.apiPrivate.client.endpoints.kitchen
        .getOrders(state.serviceId)
        .then((response) => {
          if (response.status >= 200 && response.status <= 204) {
            return response.data.data;
          }
        })
        .then((data) => {
          state.orders = data;
          state.lastUpdated = now;
        });
    },

    STOP_TASK(state) {
      if (state.job != null) {
        window.clearInterval(state.job);
        state.job = null;
      }
    },
  },
};
