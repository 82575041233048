import store from "@/store";
import router from "@/router";
import jwt_decode from "jwt-decode";

export default {
  namespaced: true,

  state: {
    tokens: {
      bearer: null,
      refresh: null,
      username: null,
    },
    claims: null,
    outlets: [],
    outletsCount: 0,
    selected: {
      service: null,
      serviceId: null,
      outlet: null,
      outletId: null,
    },
  },

  actions: {
    storeTokens({ commit }, tokens) {
      commit("STORE_TOKENS", tokens);
    },

    storeOutlets({ commit }, outlets) {
      commit("STORE_OUTLETS", outlets);
    },

    storeService({ commit }, data) {
      commit("STORE_SERVICE", data);
    },

    logout({ commit }) {
      commit("STORE_TOKENS", {
        bearer: null,
        refresh: null,
        username: null,
      });

      commit("STORE_SERVICE", {
        selected: {
          service: null,
          outlet: null,
        },
      });

      commit("STORE_OUTLETS", []);

      window.domainCookie.remove("authentication");

      router.push("/login");
    },

    // This method recovers a previous login if applied.
    recover({ commit, state }) {
      if (state.tokens.username != null) {
        return {
          authenticated: true,
          tokens: state.tokens,
        };
      }

      try {
        let tokens = JSON.parse(window.domainCookie.get("authentication"));

        if (
          tokens != null &&
          tokens.username != null &&
          tokens.bearer != null &&
          tokens.refresh != null
        ) {
          commit("STORE_TOKENS", tokens);

          return {
            authenticated: true,
            tokens: tokens,
          };
        }
      } catch {
        // Do nothing.
      }

      return {
        authenticated: false,
        reason: "No authentication data could be found.",
      };
    },
  },

  mutations: {
    STORE_TOKENS(state, tokens) {
      window.domainCookie.set("authentication", JSON.stringify(tokens));
      store.dispatch("apiPrivate/useTokens", tokens);
      state.tokens = tokens;

      try {
        state.claims = jwt_decode(tokens.bearer);
      } catch {
        state.claims = null;
      }
    },

    STORE_OUTLETS(state, outlets) {
      state.outlets = outlets;
      state.outletsCount = (outlets ?? []).length;
    },

    STORE_SERVICE(state, data) {
      if (data?.service?.id != null) {
        store.dispatch("cart/selectService", data?.service?.id);
      }

      state.selected = {
        service: data?.service,
        serviceId: data?.service?.id,
        outlet: data?.outlet,
        outletId: data?.outlet?.id,
      };
    },
  },

  getters: {
    tokens: (state) => state.tokens,
    outlets: (state) => state.outlets,
    outletsCount: (state) => state.outletsCount,
    singleOutlet: (state) => state.outletsCount == 1,
    selected: (state) => state.selected,
  },
};
