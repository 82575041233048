import store from "@/store";
import * as apiPublic from "@tucktrucks/platform-base-public";

export default {
  namespaced: true,

  state: {
    client: null,
    networkError: false,
    networkErrorLast: {},
  },

  mutations: {
    INSTANTIATE_CLIENT(state) {
      if (state.client === null) {
        state.client = new apiPublic.Client();
        state.client.domain = process.env.VUE_APP_PUBLIC_API_DOMAIN;
        state.client.sessionId = window.sessionId;
        state.client.fingerprint = window.fingerprint;

        state.client.onSuccess = (response) => {
          store.dispatch("network/onSuccess", response);
        };

        // Catches all errors, does not include response info.
        //state.client.onError = (error) => { window.log.error("[api]", error); }

        // Catches axios errors, includes status and response - Note, this will include intentional 404 responses etc.
        //state.client.onAxiosError = (status, error) => { window.log.error("[api.axios]", status, error) }

        // Catches axios from 400-499, includes status and response - Note, this will include intentional 404 responses etc.
        //state.client.onClientError = (status, error) => { window.log.warn("[api.client]", status, error) }

        // Catches axios from 500-599, includes status and response
        state.client.onServerError = (status, error) => {
          //window.log.error("[api.server]", status, error);
          store.dispatch("network/onError", error);
        };

        // Catches network errors, i.e. server ignored request, includes response - this can be used to detect lack of connectivity
        state.client.onNetworkError = (error) => {
          //window.log.error("[api]", error)
          store.dispatch("network/onError", error);
        };

        store.dispatch("repoCharities/instantiate");
        store.dispatch("repoItems/instantiate");
        store.dispatch("repoEvents/instantiate");
        store.dispatch("repoMenus/instantiate");
        store.dispatch("repoOrders/instantiate");
        store.dispatch("repoServices/instantiate");
        store.dispatch("repoServiceItems/instantiate");
        store.dispatch("repoServiceComponents/instantiate");
        store.dispatch("repoComponents/instantiate");
        store.dispatch("repoItemComponents/instantiate");
        store.dispatch("repoAllergens/instantiate");
        store.dispatch("cart/queueEndListener");
      }
    },
  },

  actions: {
    instantiateClient({ commit }) {
      commit("INSTANTIATE_CLIENT");
    },
  },

  getters: {},
};
