import store from "@/store";

export async function createZettleCartIntent(amount, cartId, cartKey) {
  return store.state.apiPrivate.client.endpoints.cartPaymentIntentsZettle
    .create(amount, cartId, cartKey)
    .then((response) => {
      let result = {
        status: response.status,
      };

      switch (response?.status) {
        case 200:
        case 201:
          result.success = true;
          result.message = "Communicating with Zettle...";
          result.intent = response.data.data;
          break;
        case 404:
          result.success = false;
          result.message = "Could not find cart...";
          break;
        case 400:
        default:
          result.success = false;
          result.message = "Could not send cart to Zettle...";
          break;
      }

      return result;
    });
}
