import PublicRepoStore from "./repoBase.js";
import store from "@/store";

const serviceRepoStore = new PublicRepoStore("ApiServiceRepo", 5);

serviceRepoStore.getters.getByIdWithQuery = () => async (id, query) => {
  try {
    const response =
      await store.state.apiPublic.client.endpoints.services.getbyIdWithQuery(
        query,
        id
      );
    return response.data.data;
  } catch (error) {
    window.log.log(error);
  }
};

export default serviceRepoStore;
