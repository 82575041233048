import PublicRepoStore from "./repoBase.js";

const orderRepoStore = new PublicRepoStore("ApiOrderRepo", 1);

orderRepoStore.actions.updateStatus = ({ commit, state }, payload) => {
  state.repo.getById(payload.id).then((item) => {
    item.status = payload.status;

    commit("UPSERT", item);
  });
};

orderRepoStore.getters.get = (state) => () => {
  return state.repo.get();
};

export default orderRepoStore;
