import {
  DateTimeFormatter,
  LocalDateTime,
  LocalTime,
  LocalDate,
} from "@js-joda/core";
require("@js-joda/timezone");
import { humanize, transform, toTitleCase } from "@alduino/humanizer/string";
import store from "@/store";

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        getCdnFile(file) {
          return process.env.VUE_APP_CDN + file;
        },

        formatMoney(money) {
          if (money == null || isNaN(money)) {
            return "";
          }

          return new Intl.NumberFormat(store.state.locale, {
            style: "currency",
            currency: store.state.currency,
          }).format(money);
        },

        formatDateTime(
          dateTime,

          format = "dd/MM/yyyy HH:mm",

          fallback = "--/--/---- --:--"
        ) {
          try {
            if (dateTime == null) {
              return fallback;
            }

            const localDateTime = LocalDateTime.parse(dateTime);

            const timezone = this.$store.getters.getTimezone;

            const zonedDateTime = localDateTime.atZone(timezone);

            const formatter = DateTimeFormatter.ofPattern(format);

            return zonedDateTime.format(formatter);
          } catch (err) {
            window.log.error(
              "[🗓️❌] Failed to process date time, the user may see unformatted data.",

              err
            );

            return dateTime;
          }
        },

        formatDate(date, format = "dd/MM/yyyy") {
          if (date == null) {
            return "--/--/----";
          }

          const localDate = LocalDate.parse(date);

          if (format == "dddd") {
            const dayOfWeek = localDate.dayOfWeek().toString().toLowerCase(); // This won't humanize unless both toString and toLowerCase are used (???)

            return transform(humanize(dayOfWeek), toTitleCase);
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          return localDate.format(formatter);
        },

        formatTime(time, format = "HH:mm") {
          if (time == null) {
            return "--:--";
          }

          // Javascript is wonderful I particularly love the way everything just turns into a string all the time for fun
          // This allows LocalTime instances which have a 0 in the seconds property to actually format. 19:43 -> 19:43:00
          if (String(time).match(/^\d\d:\d\d$/g)) {
            time = `${time}:00`;
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          const localTime = LocalTime.parse(time);
          return localTime.format(formatter);
        },

        roundTimeMinutes(time, roundTo = 10, roundingMode = "mid") {
          if (typeof time == typeof "") {
            time = LocalTime.parse(time);
          }

          if (roundTo < 1 || 60 % roundTo != 0) {
            throw "Must round to a factor of 60 - whole values between 1 and 60";
          }

          let minute = time.minute();

          switch (roundingMode) {
            case "up":
              minute = minute + (roundTo - (minute % roundTo));
              break;
            case "midpoint":
              minute += roundTo * 0.5;
              minute = minute - (minute % roundTo);
              break;
            case "down":
              minute = minute - (minute % roundTo);
              break;
          }

          return new LocalTime(time.hour(), minute, 0);
        },
      },
    });
  },
};
