export default {
  namespaced: true,

  state: () => ({
    menuSections: [],
    channels: [],
    eatIn: true,
    takeOut: true,
  }),

  actions: {
    load({ commit }) {
      commit("LOAD");
    },

    update({ commit }, { menuSections, channels }) {
      commit("UPDATE_FILTERS", { menuSections, channels });
    },

    toggleEatIn({ commit }) {
      commit("TOGGLE_EAT_IN");
    },

    toggleTakeOut({ commit }) {
      commit("TOGGLE_TAKE_OUT");
    },
  },

  mutations: {
    LOAD(state) {
      try {
        const storedFilterOptions = window.localStorage.getItem("viewFilters");
        const options = JSON.parse(storedFilterOptions);

        state.menuSections = options.menuSections ?? [];
        state.channels = options.channels ?? [];
        state.eatIn = options.eatIn ?? true;
        state.takeOut = options.takeOut ?? true;
      } catch {
        state.menuSections = [];
        state.channels = [];
        state.eatIn = true;
        state.takeOut = true;
      }
    },

    UPDATE_FILTERS(state, { menuSections, channels }) {
      state.menuSections = menuSections ?? [];
      state.channels = channels ?? [];

      window.localStorage.setItem(
        "viewFilters",
        JSON.stringify({
          menuSections: state.menuSections,
          channels: state.channels,
          eatIn: state.eatIn,
          takeOut: state.takeOut,
        })
      );
    },

    TOGGLE_EAT_IN(state) {
      state.eatIn = !state.eatIn;

      if (!state.eatIn && !state.takeOut) {
        state.eatIn = true;
      }

      window.localStorage.setItem(
        "viewFilters",
        JSON.stringify({
          menuSections: state.menuSections,
          channels: state.channels,
          eatIn: state.eatIn,
          takeOut: state.takeOut,
        })
      );
    },

    TOGGLE_TAKE_OUT(state) {
      state.takeOut = !state.takeOut;

      if (!state.eatIn && !state.takeOut) {
        state.takeOut = true;
      }

      window.localStorage.setItem(
        "viewFilters",
        JSON.stringify({
          menuSections: state.menuSections,
          channels: state.channels,
          eatIn: state.eatIn,
          takeOut: state.takeOut,
        })
      );
    },
  },

  getters: {
    getIfShowAll: (state) =>
      state.channels.length === 0 && state.menuSections.length === 0,
    getIfShowAllMenuSections: (state) => state.menuSections.length === 0,
    getIfShowAllChannels: (state) => state.channels.length === 0,

    getMenuSectionIds: (state) => state.menuSections.map((s) => s.id),
    getMenuSections: (state) => state.menuSections,
    getMenuSectionItems: (state) =>
      state.menuSections.map(({ items }) => items).flat(),
    getChannelIds: (state) => state.channels.map((c) => c.id),
    getChannels: (state) => state.channels,

    getAppliedCount: (state) =>
      state.channels.length +
      state.menuSections.length +
      (state.eatIn ? 0 : 1) +
      (state.takeOut ? 0 : 1),

    getEatIn: (state) =>
      !state.eatIn && !state.takeOut ? true : state.eatIn ?? true,
    getTakeOut: (state) =>
      !state.eatIn && !state.takeOut ? true : state.takeOut ?? true,
  },
};
