import store from "../store";
import { createRouter, createWebHistory } from "vue-router";
import { Authenticate } from "../helpers/authentication";

store.dispatch("settings/initialize");

const routes = [
  {
    path: "/",
    redirect: "/services", // If you just land, try to go to the service picker. If this doesn't work, this will bounce you back to login.
  },
  {
    name: "/login",
    path: "/login",
    meta: {
      allowAnonymous: true,
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    name: "/services",
    path: "/services",
    meta: {
      allowAnonymous: false,
      cartView: true,
    },
    component: () => import("@/views/ServicePicker.vue"),
  },
  {
    name: "/service/id",
    path: "/service/:serviceId",
    meta: {
      allowAnonymous: false,
      cartView: true,
    },
    component: () => import("@/views/Main.vue"),
  },
  {
    name: "/error",
    path: "/error",
    meta: {
      allowAnonymous: true,
    },
    component: () => import("@/views/Error.vue"),
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    meta: {
      allowAnonymous: true,
    },
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // Allow hash links to go through unmodified
    if (to.hash) {
      return {
        el: to.hash,
      };
    }

    if (process.env.VUE_APP_LOG_ROUTES != null) {
      window.log.info(
        `[🧭] ${from.path} ➡️ ${to.path} [${savedPosition?.left ?? 0}, ${
          savedPosition?.top ?? 0
        }]`
      );
    }

    // always scroll to top
    return { top: 0 };
  },
  routes,
});

router.beforeResolve(async (to) => {
  const authentication = await Authenticate(to);

  if (authentication?.action != null) {
    switch (authentication.action) {
      case "redirect": {
        if (authentication.serviceId == null) {
          router.push(authentication.target);
        } else {
          router.push({
            name: authentication.target,
            params: { serviceId: authentication.serviceId },
          });
        }

        return;
      }

      case "logout": {
        await store.dispatch("authentication/logout");
        router.push("/login");

        return;
      }
    }
  }

  // If authentication is happy, we're good to proceed.
  // Please note, certain endpoints will automatically redirect back to certain stages depending on results.
});

export default router;
