import store from "@/store";
import axios from "axios";

export default {
  namespaced: true,

  state: {
    networkError: false,
    networkErrorFirst: null,
    networkErrorCount: 0,
    version: null,
    job: null,
  },

  actions: {
    onSuccess({ commit, state }, response) {
      if (state.networkError) {
        commit("CLEAR_NETWORK_ERROR", response);
      }
    },

    onError({ commit }, error) {
      if (
        error?.response?.status != null &&
        error.response.status >= 500 &&
        error.response.status <= 599
      ) {
        commit("ON_SERVER_ERROR", error);
      } else {
        commit("ON_NETWORK_ERROR", error);
      }
    },

    startProbe({ commit }, sessionId) {
      commit("START_PROBE", sessionId);
    },
  },

  mutations: {
    CLEAR_NETWORK_ERROR(state) {
      const recovered = new Date();

      try {
        window.log.info(
          `[API.Network] Recovered in ${
            recovered - state.networkErrorFirst.when
          }ms.`
        );
      } catch {
        window.log.info("[API.Network] Recovered");
      }

      state.networkError = false;
      state.networkErrorFirst = null;
      state.networkErrorCount = 0;

      if (state.job != null) {
        window.clearInterval(state.job);
        state.job = null;
      }
    },

    ON_SERVER_ERROR(state, error) {
      error.stack = "REDACTED";
      error.when = new Date();

      window.log.error(
        `[API.Server] Server error. ${error?.response?.status} ${error?.response?.statusText}`,
        error
      );
    },

    ON_NETWORK_ERROR(state, error) {
      error ??= {};
      error.stack = "REDACTED";
      error.when = new Date();

      state.networkError = true;

      if (state.networkErrorFirst == null) {
        state.networkErrorFirst = error;
      }

      state.networkErrorCount += 1;

      if (state.job == null)
        state.job = window.setInterval(() => {
          store.state.apiPublic.client
            .isApiAvailable()
            .then((response) => {
              store.dispatch("network/onSuccess", response);

              window.clearInterval(state.job);
              state.job = null;
            })
            .catch((error) => {
              store.dispatch("network/onError", error);
            });
        }, 20000);

      window.log.warn("[API.Network]", error);
    },

    START_PROBE(state, sessionId) {
      let seconds = 100;

      // Every x seconds, grab the public version number. If it has incremented, force an update.
      window.setIntervalAndExecute(async () => {
        let redirect = false;
        let currentVersion;

        try {
          const url = `https://${process.env.VUE_APP_PUBLIC_API_DOMAIN}/api/v1/meta/version`;
          const request = await axios.get(url, {
            headers: {
              "TT-SessionId": sessionId,
            },
          });

          if (request.status == 200) {
            currentVersion = request.data;
          } else {
            throw new Error("Failed to download latest version, assuming 1.0.");
          }
        } catch {
          currentVersion = "1.0";
        }

        state.version = currentVersion;

        const lastVersion = window.localStorage.getItem("lv");

        if (
          lastVersion == null ||
          lastVersion == "" ||
          lastVersion != currentVersion
        ) {
          redirect = true;
        }

        if (redirect) {
          window.localStorage.setItem("lv", currentVersion);

          window.setInterval(() => {
            let cacheBuster = new URLSearchParams(window.location.search);
            cacheBuster.set("lv", currentVersion);
            window.location.search = cacheBuster.toString();
          }, 2000);
        }
      }, seconds * 1000);
    },
  },

  getters: {
    networkError: (state) => () => state.networkError,
    networkLostAt: (state) => () => state.networkErrorFirst?.when,
  },
};
